import React from "react";
import { graphql } from "gatsby";
import Container from "@material-ui/core/Container";
import Call from "../components/schedule-a-call";
import Cards from "../components/cards";
import Contact from "../components/contact";
import Customers from "../components/customers";
import DemoMulti from "../components/demo-multi";
import Features from "../components/features";
import HeroSlider from "../components/hero-slider";
import Layout from "../components/layout";
import MainCards from "../components/main-cards";
import ModelSteps from "../components/model-steps";
import Spacer from "../components/spacer";
import SEO from "../components/seo";

const Components = ({ data }) => {
  const fm = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title={fm.seoTitle} description={fm.seoDescription} />

      <Spacer h={160} />

      <section>
        <Container>
          <HeroSlider data={fm.hero} disableSlider={fm.disable_hero_slider} />
        </Container>
      </section>

      <Spacer h={40} />

      <section>
        <Container>
          <DemoMulti data={fm.demos} />
        </Container>
      </section>

      {fm.how_it_works && (
        <>
          <Spacer h={160} />
          <div style={{ maxWidth: 800, margin: "auto" }}>
            <ModelSteps how_it_works={fm.how_it_works} />
          </div>
        </>
      )}

      {fm.feature_data.show && (
        <>
          <Spacer h={160} />
          <h3
            style={{
              fontSize: 32,
              fontWeight: 400,
              margin: "0 0 20px",
              textAlign: "center",
              color: "#546fff",
            }}
          >
            {fm.feature_data.heading}
          </h3>
          <Features
            features={fm.feature_data.features}
            iconHeight={fm.feature_data.height}
            iconWidth={fm.feature_data.width}
          />
        </>
      )}

      {fm.use_cases && fm.use_cases.cards.length > 0 && (
        <>
          <Spacer h={160} />

          <section style={{ overflow: "hidden" }}>
            <Container>
              <div style={{ textAlign: "center" }}>
                <h3
                  style={{ fontSize: 32, fontWeight: 600, margin: "0 0 40px" }}
                >
                  Use Cases
                </h3>
              </div>
              <Cards cards={fm.use_cases.cards} />
            </Container>
          </section>
        </>
      )}

      {fm.main_feature_data &&
        fm.main_feature_data.show &&
        fm.main_feature_data.cards.length > 0 && (
          <>
            <Spacer h={160} />

            <section style={{ overflow: "hidden" }}>
              <Container>
                <div style={{ textAlign: "center" }}>
                  <h3
                    style={{
                      fontSize: 32,
                      fontWeight: 600,
                      margin: "0 0 40px",
                    }}
                  >
                    {fm.main_feature_data.title}
                  </h3>
                </div>
                <MainCards cards={fm.main_feature_data.cards} />
              </Container>
            </section>
          </>
        )}

      {fm.case_study && fm.case_study.cards.length > 0 && (
        <>
          <Spacer h={160} />

          <section style={{ overflow: "hidden" }}>
            <Container>
              <div style={{ textAlign: "center" }}>
                <h3
                  style={{ fontSize: 32, fontWeight: 600, margin: "0 0 40px" }}
                >
                  {fm.case_study.title}
                </h3>
              </div>
              <MainCards cards={fm.case_study.cards} />
            </Container>
          </section>
        </>
      )}

      <Spacer h={80} />

      <Call />

      <div style={{ marginTop: 160 }}></div>

      <section>
        <Container>
          <Customers />
        </Container>
      </section>

      <div style={{ marginTop: 320 }}></div>

      <Contact />
    </Layout>
  );
};

export default Components;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        path
        seoTitle
        seoDescription
        disable_hero_slider
        hero {
          getStartedLink
          illustration
          title
          subtitle {
            line1
            line2
          }
        }
        demos {
          title
          subtitle
          tabs {
            tab_name
            demo {
              demo_type
              input
              output {
                image {
                  url
                }
                result
              }
              output_type
            }
          }
        }
        how_it_works {
          heading
          heading2
          steps {
            name
            summary
            poster
          }
        }
        use_cases {
          cards {
            href
            poster
            summary
            title
          }
        }
        main_feature_data {
          show
          title
          cards {
            href
            poster
            summary
            title
            link_text
          }
        }
        case_study {
          title
          cards {
            href
            poster
            summary
            title
            link_text
          }
        }
        feature_data {
          show
          heading
          height
          width
          features {
            icon
            title
            summary
          }
        }
      }
    }
  }
`;
