import React from "react";
import css from "./main-cards.module.css";

const MainCards = ({ cards }) => {
  return (
    <div className={css.mainCard}>
      {cards.map((card, index) => (
        <div key={index} className={css.row}>
          <div className={css.column1 + " " + css.text1}>
            <div style={{ fontSize: 24, fontWeight: 600, margin: "0 0 5px" }}>
              {card.title}
            </div>
            <div className={css.line}></div>
            <div style={{ margin: "20px 0 0" }}>{card.summary}</div>
            <div className={css.link}>
              <a href={card.href}>
                {card.href
                  ? card.link_text
                    ? card.link_text
                    : "More info →"
                  : ""}
              </a>
            </div>
          </div>
          <div className={css.column2}>
            <img className={css.poster} src={card.poster}></img>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MainCards;
