import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import css from "./demo-multi.module.css";
import Spacer from "./spacer";
import Demo from "./demo";

const useStyles = makeStyles(() => ({
  tabsRoot1: {
    minHeight: "auto",
  },
  tabRoot1: {
    opacity: 1,
    whiteSpace: "nowrap",
    minHeight: "auto",
    minWidth: "auto",
    maxWidth: 280,
    marginLeft: 12,
    marginRight: 12,
  },
  tabSelected1: {
    color: "#546fff",
  },
  indicator1: {
    background: "#546fff",
  },
}));

const Demo2 = ({ data }) => {
  const classes = useStyles();
  const [tabs1_index, set_tabs1_index] = useState(0);
  const { title, tabs } = data;

  const handleChange1 = (event, value) => {
    set_tabs1_index(value);
  };

  const industryTabs = tabs.map((item) => item.tab_name);
  const demo = tabs[tabs1_index].demo;

  return (
    <div>
      <p className={css.title}>{title}</p>

      {/* <p className={css.subtitle}>{subtitle}</p> */}

      <Spacer h={40} />

      {industryTabs && (
        <div className={css.tabs1Wrapper1}>
          <div className={css.tabs1Wrapper2}>
            <Tabs
              value={tabs1_index}
              onChange={handleChange1}
              variant="scrollable"
              classes={{
                root: classes.tabsRoot1,
                indicator: classes.indicator1,
              }}
            >
              {tabs.map((v, index) => (
                <Tab
                  key={index}
                  label={v.tab_name}
                  disableRipple={true}
                  classes={{
                    root: classes.tabRoot1,
                    selected: classes.tabSelected1,
                  }}
                />
              ))}
            </Tabs>
          </div>
        </div>
      )}

      <div>
        <Demo demo={demo} />
      </div>
    </div>
  );
};

export default Demo2;
