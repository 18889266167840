import React from "react";
import Container from "@material-ui/core/Container";
import _classes from "../styles/main.module.css";
import css from "./schedule-a-call.module.css";
import { scheduleDiscoveryCall } from "../utils/calendly";

const ScheduleCall = () => (
  <div className={css.root}>
    <Container>
      <div className={css.row}>
        <div className={css.item}>
          <div className={css.box1}>
            <p className={css.title}>Schedule a call with an AI expert.</p>
            <button onClick={scheduleDiscoveryCall} className={_classes.button}>
              Schedule a call
            </button>
          </div>
        </div>
        <div className={css.item}>
          <img
            className={`${css.icon} lazyload`}
            data-src="/media/call.png"
            alt=""
          />
        </div>
      </div>
    </Container>
  </div>
);

export default ScheduleCall;
