import React from "react";
import clsx from "clsx";
import _classes from "../styles/main.module.css";
import css from "./hero-slider.module.css";
import Carousel from "./carousel";
import { scheduleDiscoveryCall } from "../utils/calendly";

class Carousel2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }

  handleChange(prevIndex, nextIndex) {
    this.setState({
      currentIndex: nextIndex,
    });
    let next_elem = document.getElementById(`hero-media-${nextIndex}`);
    if (next_elem) {
      let src = next_elem.src;
      next_elem.src = "";
      setTimeout(() => {
        next_elem.src = src;
        if (next_elem.tagName === "VIDEO") {
          next_elem.currentTime = 0;
          next_elem.play();
        }
      }, 0);
    }

    let prev_elem = document.getElementById(
      `${prevIndex}-${this.props.data[prevIndex].illustration}`
    );
    if (prev_elem && prev_elem.tagName === "VIDEO") {
      prev_elem.pause();
      prev_elem.currentTime = 0;
    }
  }

  render() {
    const { data } = this.props;
    const { currentIndex } = this.state;
    const currentSlide = data[currentIndex];

    return (
      <div>
        <div className={css.row}>
          <div className={css.column1}>
            {currentSlide && (
              <div style={{ maxWidth: 500 }}>
                <p className={css.title}>{currentSlide.title}</p>
                <p className={css.subtitle}>
                  <span className={css.line1}>
                    {currentSlide.subtitle.line1}
                  </span>
                  <span className={css.line2}>
                    {currentSlide.subtitle.line2}
                  </span>
                </p>
                <div>
                  <a
                    className={_classes.button}
                    style={{ marginTop: 16 }}
                    href={
                      currentSlide.getStartedLink || "https://app.nanonets.com"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Get Started
                  </a>
                  <br />
                  <button
                    onClick={scheduleDiscoveryCall}
                    className={clsx(_classes.button, _classes.button_outlined)}
                    style={{ marginTop: 16 }}
                  >
                    Request a Demo
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className={`${css.column2} hero-slider`}>
            {data.length > 0 &&
              (data.length > 1 ? (
                <Carousel
                  options={{
                    slideWidth: 1,
                    autoplay: true,
                    dragging: true,
                    wrapAround: true,
                    withoutControls: false,
                    autoplayInterval: 5000,
                    beforeSlide: this.handleChange.bind(this),
                  }}
                >
                  {data.map((item, index) => (
                    <Media key={index} item={item} index={index} />
                  ))}
                </Carousel>
              ) : (
                <Media item={data[0]} index={0} />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const Media = ({ item, index }) => {
  return /([/|.|\w|\s|-])*\.(?:jpeg|jpg|gif|png|webp|svg)/gi.test(
    item.illustration
  ) ? (
    <img
      id={`hero-media-${index}`}
      className={`${css.media} lazyload`}
      // imgix does not transformation for gif and webp files
      data-src={
        item.illustration +
        (/([/|.|\w|\s|-])*\.(?:gif|webp)/gi.test(item.illustration)
          ? ""
          : "?w=800")
      }
      alt=""
    />
  ) : (
    <video
      id={`hero-media-${index}`}
      className={`${css.media} lazyload`}
      data-src={item.illustration + "?w=800"}
      autoPlay
      muted
      loop
    ></video>
  );
};

export default Carousel2;
